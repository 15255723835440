import {
  //SubscriptionStatuses,
  User,
  UserRoles,
} from '../../shared/types/generated';
import { RBACSchema } from './rbac-permissions';
import { RBACPermissions } from './rbac-types';
import { GUEST_ROLE } from '../../shared/constans';

export const getHighestRole = (user: User | undefined | null): string => {
  const totalRoles = user?.roles_on_users?.length ?? 0;
  let role = GUEST_ROLE;
  for (let i = 0; i < totalRoles; i++) {
    if (user?.roles_on_users?.[i]?.role?.name === UserRoles.Administrator) {
      role = UserRoles.Administrator;
    } else if (user?.roles_on_users?.[i]?.role?.name === UserRoles.Suscriptor) {
      role = UserRoles.Suscriptor;
    } else if (user?.roles_on_users?.[i]?.role?.name === UserRoles.User) {
      role = UserRoles.User;
    }
  }
  return role;
};
export const checkUserRole = (
  user: User | undefined | null,
  permission: RBACPermissions,
): boolean => {
  const roleName = getHighestRole(user);
  const subscription =
    roleName === UserRoles.Suscriptor || roleName === UserRoles.Administrator;
  //console.log({ roleName, subscription, permission });

  if (permission === RBACPermissions.CAN_SUBSCRIPTION && subscription)
    return false;

  return !!RBACSchema.find(
    (schema) =>
      schema.Role === roleName && schema.Permissions.includes(permission),
  );
};
