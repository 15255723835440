import { Box, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    banner: {
      backgroundImage: 'url("/plans-banner.png")',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    },
    title: {
      fontSize: '35px !important',
      fontWeight: '700 !important',
      lineHeight: '38px !important',
      fontFamily: 'acumin-pro',
      textTransform: 'uppercase',
      color: '#FF3C41',
      /**
      *  color: '#FF3E00',
      background: 'linear-gradient(to bottom right,#4F5BDB,#FF4C00, #E2E245)',
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',
      */
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
      },
    },
    subTitle: {
      fontSize: '22px !important',
      lineHeight: '28px !important',
      fontWeight: '700 !important',
      color: '#ffffff',
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
      },
    },
    parragraph: {
      fontSize: '20px !important',
      lineHeight: '28px !important',
      paddingBottom: '5px',
      color: '#ffffff',
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
      },
    },
    littleLetter: {
      fontSize: '13px !important',
      lineHeight: '15.6px !important',
      color: '#b7b7b7',
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
        fontSize: '12px !important',
        lineHeight: '18px !important',
      },
    },
    littleLetterImg: {
      fontSize: '14px !important',
      lineHeight: '20px !important',
      color: '#b7b7b7',
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
        fontSize: '12px !important',
        lineHeight: '18px !important',
      },
    },
    giftBagCard: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    giftBagCardContainer: {
      width: '534px',
      borderRadius: '20px',
      padding: '20px 46px 20px 46px',
      background: '#161616',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        padding: '20px 26px 20px 26px',
      },
    },
    giftBagText: {
      width: '252px',
      height: '310px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      paddingBottom: '20px',
      paddingTop: '20px',
      [theme.breakpoints.down('md')]: {
        height: '290px',
        paddingBottom: '10px',
        paddingTop: '10px',
      },
    },
    giftBagImg: {
      width: '290px',
      height: '350px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        height: '280px',
      },
    },
    benefits: {
      paddingTop: '15px !important',
      padding: '0 40px !important',
      [theme.breakpoints.down('sm')]: {
        paddingTop: '15px !important',
        padding: '0 10px !important',
      },
    },
  }),
);

export const GiftBagCard: React.FC = () => {
  const classes = useStyles();
  return (
    <Box
      display={'flex'}
      fontFamily={'acumin-pro'}
      justifyContent={'center'}
      component={'div'}
      style={{ backgroundColor: '' }}
    >
      <Box className={classes.giftBagCardContainer}>
        <Box className={classes.giftBagCard}>
          <Box className={classes.giftBagText}>
            <Typography fontFamily={'acumin-pro'} className={classes.title}>
              Recibe <br /> un regalo especial*
            </Typography>
            <Typography fontFamily={'acumin-pro'} className={classes.subTitle}>
              en la compra de una membresía profesional:
            </Typography>
            <Typography
              fontFamily={'acumin-pro'}
              className={classes.parragraph}
            >
              bolsa tote + un libro autografiado de nuestros periodistas.
            </Typography>
            <Typography
              fontFamily={'acumin-pro'}
              className={classes.littleLetter}
            >
              *Hasta agotar existencias
              <br />
              *No acumulable con otras promociones
            </Typography>
          </Box>
          <Box className={classes.giftBagImg}>
            <img
              src="/regalo-suscripciones-febrero.png"
              alt="funda de regalo"
              width={'100%'}
              height={'100%'}
              style={{ objectFit: 'contain' }}
            />
          </Box>
        </Box>
        {/**<Typography
          textAlign={'center'}
          mt={'5px'}
          className={classes.littleLetterImg}
          fontFamily={'acumin-pro'}
        >
          Promoción de verano hasta el 30 de septiembre de 2024.
        </Typography> */}
      </Box>
    </Box>
  );
};
